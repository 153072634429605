import React from 'react'
import { NavLink } from 'react-router-dom'
import { Label } from 'semantic-ui-react'
import AppRoutes from 'routes/app.routes'
export const Layout = () => {
    return <div className="grid-container">
        <header className="header__panel">
                <div className="header__search"></div>
                <div className="header__avatar">
                    <NavLink to="/logout">
                        <Label as='a' content='Выход' icon='user' size='large' />
                    </NavLink>
                </div>
            </header>
            <aside className="sidenav">
                <div className="sidenav__brand">
                    <a className="sidenav__brand-link" href="#">Client</a>
                </div>
                <ul className="sidenav__list">
                    <NavLink to="/"><li className="sidenav__list-item">Запросы</li></NavLink>
                    <NavLink to="/orders/"><li className="sidenav__list-item">Заказы</li></NavLink>
                </ul>
            </aside>
            <main className="main">
                <AppRoutes />
            </main>
            <footer className="footer">
                <div className="footer__copyright">{''}</div>
                <div className="footer__signature">{''}</div>
            </footer>
        </div>
}