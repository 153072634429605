import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '_store';
import {useEffect} from "react";
import {history} from "../../_helpers";

export const LogOut = () => {
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());
    useEffect(() => {
        authUser && logout()
        return history.navigate('/login');
    }, [authUser]);
}