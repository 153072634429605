import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Grid, Message, Segment } from 'semantic-ui-react'
import { history } from '_helpers';
import { authActions } from '_store';

export const Login = () => {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);
    
    useEffect(() => {
        authUser && history.navigate('/')
    }, [authUser])

    // form validation rules
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) }
    const { register, handleSubmit, formState: { isSubmitting } } = useForm(formOptions)
    const onSubmit = ({ username, password }) => dispatch(authActions.login({ username, password }))

    return <div className="page-login">
        <Grid textAlign='center' className="page-login-form" verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 550 }}>
                <Segment padded style={{ borderRadius: 5 }}>
                    <Form className='attached fluid' onSubmit={handleSubmit(onSubmit)} size={'large'} >
                        <Form.Field>
                            <input name="username" autoComplete="off" required placeholder='Логин' type="text" {...register('username')} />
                        </Form.Field>
                        <Form.Field>
                            <input name="password" required placeholder='Пароль' type="password" {...register('password')} />
                        </Form.Field>
                        <Button disabled={isSubmitting} color='blue'>
                            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}Вход
                        </Button>
                    </Form>
                    {authError && <Message negative content={authError.message} />}
                </Segment>
            </Grid.Column>
        </Grid>
    </div>
}