import { Requests, Orders } from 'pages'
import { Route, Routes } from 'react-router-dom'

const AppRoutes = () => (
    <Routes>
        <Route path='/' element={<Requests />} />
        <Route path='/orders' element={<Orders />} />
    </Routes>
)

export default AppRoutes
