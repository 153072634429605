import {useDispatch, useSelector} from "react-redux";
import { useMutation, useQuery } from "react-query";
import { Api, authUuid } from "../../_helpers";
import {cartActions} from "../../_store";
import noticeError from "../../hooks/useError";
import {Button, Modal, Table, Header, Segment} from "semantic-ui-react";
import NumberInput from "semantic-ui-react-numberinput";
import { useState, useMemo } from "react";

export const BasketModal = ({ close: callback }) => {
    const dispatch = useDispatch()
    const open = useSelector(x => x.cart.modal)
    const item = useSelector(({ cart }) => cart.item) || []
    const [list, setList] = useState([])

    const handlerQuery = (data) => ({ data })

    const { mutate: createOrder, isLoading: isLoadingCreate } =
        useMutation((data) => Api().put('/orders/create', authUuid(data)), {
        onSuccess: () => {
            dispatch(cartActions.clear(true))
            callback()
        },
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    const { isFetching } = useQuery(['orders.basket.info', [open]],
        () => Api().post('/orders/basket.info', authUuid({ guid: item.map(({guid}) => guid) })),
        { enabled: open, onSuccess: ({data}) => setList(data) })

    const close = () => dispatch(cartActions.open(false))

    const cartList = useMemo(() => item.map(el => ({
        ...list.find(({ guid }) => guid === el.guid),
        ...el
    })), [item, list])

    return <Modal open={open} onClose={close} style={{ width: '60%' }}>
        <Modal.Header style={{ padding: '1em 1.25em'}}>
            <Header as='h4'>Корзина заказа</Header>
        </Modal.Header>
        <Modal.Content scrolling>
            <Segment loading={isFetching} padded>
            <Table celled striped selectable compact unstackable size="small" >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{ minWidth: '180px' }}>Производитель</Table.HeaderCell>
                        <Table.HeaderCell style={{ minWidth: '140px' }}>Артикул</Table.HeaderCell>
                        <Table.HeaderCell>Наименование</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Цена</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Кол-во</Table.HeaderCell>
                        <Table.HeaderCell/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {cartList.map(({ brand, article, description, quant, price, guid }, i) => {
                        return <Table.Row key={i}>
                            <Table.Cell>{brand}</Table.Cell>
                            <Table.Cell>{article}</Table.Cell>
                            <Table.Cell>{description}</Table.Cell>
                            <Table.Cell textAlign={'right'}>{price}</Table.Cell>
                            <Table.Cell width={1}>
                                <NumberInput
                                    buttonPlacement="right"
                                    allowEmptyValue
                                    placeholder=""
                                    minValue={1}
                                    maxValue={100}
                                    showError={Boolean(!quant)}
                                    value={String(quant)}
                                    onChange={(val) => dispatch(cartActions.add({guid, quant: val}))}
                                    className="inputRight"
                                />
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                                <Button icon='delete' onClick={() => dispatch(cartActions.deleted(guid))} />
                            </Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan='15'>

                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
            </Segment>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => createOrder(handlerQuery(item))}
                    content="Создать" primary
                    disabled={Boolean(!item.length)} loading={isLoadingCreate} />
            <Button onClick={close} content="Закрыть" />
        </Modal.Actions>
    </Modal>
}
