import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './_store';
import { App } from './App';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <ReactNotifications />
            <App />
        </BrowserRouter>
    </Provider>
</React.StrictMode>)