import React, {useState, useMemo} from 'react'
import {
    Table,
    Container,
    Button,
    Form,
    Segment,
    Dimmer,
    Loader,
    Dropdown,
    Divider,
    Input,
    Checkbox,
    Popup,
    FormField,
    Grid,
    Message, MessageHeader
} from 'semantic-ui-react'
import useStore from "hooks/useStore";
import { useQuery, useMutation } from 'react-query'
import {Api, authUuid} from "../../_helpers";
import { ModalExcelExportClient } from 'pages/Clients/component/ModalExcelExportClient'
import { useNavigate } from "react-router-dom";
import noticeError from 'hooks/useError'
import { HighLightWords } from '../HighlightWords'
const ITEM_DEFAULT = {
    article: '',
    brand: { value: '' },
    description: '',
    quantity: 1
}

const NoticeErrorCyrillic = ({ article }) =>
    <HighLightWords text={article} searchWords={[...new Set(article.replace(/[^А-ЯЁ]/gi, "").split(''))]} />

export const Invoice = ({ close }) => {
    const navigate = useNavigate()
    const { invoiceBuffer: buffer, addBuffer, removeBuffer, removeBufferAll } = useStore(store => store);

    const [item, setItem] = useState(ITEM_DEFAULT)
    const [brands, setBrands] = useState([])
    const [search, setSearch] = useState('')
    const [openExport, setOpenExport] = useState(false)

    const [speed, setSpeed] = useState(false)

    const { isFetched } = useQuery(
        ['brands', search], () => Api().get('/brands', { params: { search }} ), {
        enabled: !!search && search.length >= 1,
        onSuccess: ({ data }) => setBrands(data.map(({id, name}) => ({ key: id, text: name, value: id })))
    })

    const { article = '', brand: { value: brand } = {}, description = '', quantity = 1 } = item || {}

    const handlerAppendBuffer = () => {
        if (!article || !quantity) {
            noticeError({message: "Необходимо заполнить обязательные поля (артикул, количество)"})
            return
        }
        if (isCyrillic) {
            noticeError({message: "Номер артикула содержит кириллицу"})
            return
        }
        addBuffer({...item, id: Math.random() * 100})
        setItem(ITEM_DEFAULT)
    }

    const handlerAppendItems = ({target: { name, value }}) => setItem(prev => ({...prev, [name]: value }))

    const handlerArticle = ({target: { value }}) => {
        setItem(prev => ({...prev, article: value.toUpperCase().replace(/[^0-9А-ЯЁa-z-.]/gi, "")}))
    }

    const handlerDelete = id => removeBuffer(id)

    const isVisibleRequestButton = !!buffer.length

    const { uuid } = authUuid({})

    const { mutateAsync: create, isSuccess, isFetching } = useMutation(data => Api().post('/client.create.request', data), {
        onSuccess: ({ data: { sendProcessing } }) => {
            removeBufferAll()
            close(true)
            sendProcessing && navigate('/requests-supplier/')
        },
        onError: (err) => noticeError({ message: err.response.data.message })
    })

    const createRequest = async (data) => {
        if (buffer.some(({ article }) => /[А-ЯЁ]/.test(article))) {
            noticeError({message: "Номер артикула содержит кириллицу"})
            return
        }

        try {
            const todo = await create(data)
            console.log(todo)
        } catch (error) {
            console.error(error)
        }
    }

/*    useEffect(() => {
        if (!load) return

        if (buffer.some(({ article }) => /[А-ЯЁ]/.test(article))) {
            noticeError({message: "Номер артикула содержит кириллицу"})
            setLoad(false)
            return
        }

        createRequest({
            sendProcessing,
            items: buffer.map(el => ({...el, label: orderNumber, uuid }))
        })
        setTimeout(() => setLoad(false), 1000)
    }, [load])*/

    const isCyrillic = useMemo(() => /[А-ЯЁ]/.test(article), [article])

    const handlerSupplier = (_, { value }) => {
        handlerAppendItems({ target: {
            name: 'brand', value: brands.find(({key}) => key === value)
        }})
    }

    const handleSearchChange = (_, { searchQuery }) => setSearch(searchQuery)

    return <div className="main-header">
        <Container fluid>
            <Dimmer.Dimmable as={Segment} dimmed={isFetching}>
                <Dimmer active={isFetching} inverted>
                    <Loader>Загрузка...</Loader>
                </Dimmer>
                <Segment padded secondary>
                    <Form autoComplete="off">
                        <Form.Group>
                            <Form.Field width={4}>
                                <label>Артикул</label>
                                <Popup
                                    wide='very'
                                    trigger={<Input
                                        width={4}
                                        error={!article}
                                        value={article}
                                        onChange={handlerArticle}
                                        maxLength={32}
                                    />}
                                    content={<>Внимание! Артикул <NoticeErrorCyrillic article={article} /> содержит кириллицу</>}
                                    on='click'
                                    open={isCyrillic}
                                    position='top center'
                                />
                            </Form.Field>
                            <Form.Field width={5}>
                                <label>Производитель</label>
                                <Dropdown
                                    isFetching={isFetched}
                                    options={brands}
                                    search
                                    onSearchChange={handleSearchChange}
                                    selection
                                    fluid
                                    // allowAdditions
                                    value={brand || ''}
                                    // onAddItem={() => {}}
                                    clearable
                                    onChange={handlerSupplier}
                                    noResultsMessage={brand === '' ? 'поиск от 3-х символов' : 'Не найдено производителя'}
                                />
                            </Form.Field>
                            <Form.Input
                                width={8}
                                name='description'
                                value={description}
                                label='Наименование'
                                onChange={handlerAppendItems}
                            />
                            <Form.Field error={!quantity} >
                                <label>Кол-во</label>
                                <Input
                                    value={quantity}
                                    min="1" max="9999" step="1"
                                    onChange={(e) =>
                                        setItem(prev => ({...prev,
                                            quantity: e.target.value !== '' ? Math.max(1, Math.min(9999, Number(e.target.value))) : null
                                        }))}
                                    type="number"
                                />
                            </Form.Field>
                            <Form.Button content='Добавить' label={'\u00A0'} onClick={() => handlerAppendBuffer(item)} />
                            <Form.Button color="blue" icon="file excel" label={'\u00A0'} onClick={() => setOpenExport(true)} />
                        </Form.Group>
                    </Form>
                </Segment>
                <ModalExcelExportClient open={openExport} close={() => setOpenExport(false)} addBuffer={addBuffer} uuid={uuid} />
                <Divider />
                <Segment style={{ overflow: 'auto', maxHeight: '40vh' }}>
                    {!!buffer.length && <Table singleLine selectable size='small'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Производитель</Table.HeaderCell>
                                <Table.HeaderCell>Артикул</Table.HeaderCell>
                                <Table.HeaderCell>Наименование</Table.HeaderCell>
                                <Table.HeaderCell>Количество</Table.HeaderCell>
                                <Table.HeaderCell textAlign='right'>-</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {buffer.map(({id, article, brand: { text: brand } = {}, description, quantity}) => <Table.Row key={id}>
                                <Table.Cell>{brand}</Table.Cell>
                                <Table.Cell>
                                    <><NoticeErrorCyrillic article={article} /></>
                                </Table.Cell>
                                <Table.Cell>{description}</Table.Cell>
                                <Table.Cell>{quantity}</Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <Button icon='trash' size='mini' onClick={() => handlerDelete(id)}/>
                                </Table.Cell>
                            </Table.Row>)}
                        </Table.Body>
                    </Table>}
                </Segment>
                <Segment padded secondary>
                    <Grid columns={2} divided centered verticalAlign='middle'>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <Form>
                                    <FormField>
                                        <Checkbox
                                            radio
                                            label='Быстрая проценка'
                                            name='requestSpeed'
                                            value={false}
                                            checked={speed === false}
                                            onChange={(e, data) => setSpeed(data.value)}
                                        />
                                    </FormField>
                                    <FormField>
                                        <Checkbox
                                            radio
                                            label='Медленная проценка'
                                            name='requestSpeed'
                                            value={true}
                                            checked={speed === true}
                                            onChange={(e, data) => setSpeed(data.value)}
                                        />
                                    </FormField>
                                </Form>
                            </Grid.Column>
                            <Grid.Column>
                                <Message warning>
                                    <MessageHeader>Быстрая проценка!</MessageHeader>
                                    <p>Какой то текст для клиента что такое быстрая проценка</p>
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                {isVisibleRequestButton && <Segment textAlign='center' padded>
                    <Button primary onClick={() => createRequest(authUuid({buffer, speed}))}>Отправить запрос</Button>
                </Segment>}
                {isSuccess && <Segment textAlign='center'>
                    <Segment.Inline>
                        Запрос отправлен
                    </Segment.Inline>
                </Segment>}
            </Dimmer.Dimmable>
        </Container>
    </div>
}