const STATUS = [
    { value: 0, key: 'created' },
    { value: 1, key: 'adopted' },
    { value: 2, key: 'working' },
    { value: 3, key: 'success' }
]
const STATUS_LABEL = [
    { text: 'Создан', label: { color: 'red', empty: true, circular: true } },
    { text: 'Принят', label: { color: 'green', empty: true, circular: true } },
    { text: 'В обработке', label: { color: 'olive', empty: true, circular: true } },
    { text: 'Обработан', label: { color: 'violet', empty: true, circular: true } },
]
export const STATUS_OPTIONS = STATUS.map(el => ({...el, ...STATUS_LABEL[el.value] }))