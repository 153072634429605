import {
    Accordion, Button, Divider, Grid, GridColumn, Icon,
    Label, List, Message, Modal, Progress,
    Segment, Table, Input
} from "semantic-ui-react";
import NumberInput from 'semantic-ui-react-numberinput';
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Api, authUuid } from "../../_helpers";
import { useDebounce } from 'use-debounce';
import {STATUS_OPTIONS} from "../../constant";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import { OrderExportExcelFile } from './OrderExportExcelFile'
import { BasketModal } from './Basket'
import { cartActions } from '_store';

export const Orders = () => {
    const [list, setList] = useState([])
    const [activePage, setActivePage] = useState(1)

    const { isFetching, refetch } = useQuery(['orders.list', [activePage]],
        () => Api().get('/orders', { params: authUuid({ page: activePage - 1 })}), {
            onSuccess: ({ data }) => setList(data)
        })

    const handlerCloseCreate = () => refetch()
    return <Segment padded>
        <CreateOrder close={handlerCloseCreate} />
        <Divider />
        <OrdersList list={list} isLoading={isFetching} />
    </Segment>
}

const CreateOrder = ({ close }) => {
    const dispatch = useDispatch()
    const cartItem = useSelector(({cart}) => cart.item)
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState('')
    const [resultList, setResultList] = useState([])
    const closed = () => {
        setOpen(false)
        close()
    }

    const handlerArticle = ({target: { value }}) => setItem(value.toUpperCase().replace(/[^0-9a-z-.]/gi, ""))

    const [ inputArticle ] = useDebounce(item, 800)

    const { isFetching } = useQuery(
        ['find.article', [inputArticle]], () => Api().get('/requests/find.item', { params: authUuid({ search: inputArticle })} ), {
            enabled: !!inputArticle && inputArticle.length >= 1,
            onSuccess: ({ data }) => setResultList(data.map( el => ({ ...el, quant2: 1 })))
        })

    return <>
            <Button content='Создать заказ' icon='plus' labelPosition='left' color="purple" onClick={() => setOpen(true)} />
            <Modal
                style={{ width: '90%', minWidth: 1300 }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Modal.Header>Создание заказа</Modal.Header>
                <Modal.Content scrolling>
                    <Segment padded secondary>
                        <Grid size="big"  columns={2}>
                            <Grid.Row>
                                <Grid.Column width={12}>
                                    <label>Артикул</label>
                                    <Input
                                        icon={item.length > 0 ? { name: 'close', link: true, onClick: () => setItem('') } : 'search'}
                                        width={10}
                                        value={item}
                                        fluid
                                        size='big'
                                        onChange={handlerArticle}
                                    />
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <label>{'\u00A0'}</label>
                                    <Button
                                        fluid
                                        content={`[${cartItem.length}]`}
                                        onClick={() => dispatch(cartActions.open(true))}
                                        size='big'
                                        icon='shop'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                    <Segment loading={isFetching} style={{ minHeight: '50vh' }} padded>
                        {resultList.length === 0 ? <Message warning header='Для поиска введите номер артикула детали'
                                                            content='Ничего не найдено'/> : <FindResultTable list={resultList} cartItem={cartItem} />}
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={closed}>Закрыть</Button>
                </Modal.Actions>
                <BasketModal close={closed} />
            </Modal>
        </>
}

const OrdersList = ({ list, isLoading }) => {
    const [exportData, setExportData] = useState([])
    const [openExport, setOpenExport] = useState(false)

    const excelExport = (data = []) => {
        setExportData(data)
        setOpenExport(true)
    }

    const panels = list.map(({ctime, docid, count, label, request}, i) => ({
        key: 'panel' + i,
        // onTitleClick: (i) => i.onClick(),
        title: {
            style: { padding: 0 },
            icon: false,
            content: <List selection>
                <List.Item>
                    <List.Content>
                        <Grid verticalAlign='middle' divided>
                            <Grid.Row stretched centered>
                                <GridColumn width={2} textAlign="center">
                                    {dayjs(ctime).format('DD.MM.YYYY HH:mm')}
                                </GridColumn>
                                <GridColumn width={2} textAlign="center">
                                    <Label style={{ textAlign: 'center' }}>{label || '-'}</Label>
                                </GridColumn>
                                <Grid.Column width={2} textAlign="right">
                                    <Label>{count || 0} шт.</Label>
                                </Grid.Column>
                                <Grid.Column width={2} textAlign="right">
                                    <Label>{count || 0} шт.</Label>
                                </Grid.Column>
                                <GridColumn width={7} textAlign={'center'}>
                                    <Progress style={{ margin: 0 }} percent={0} progress/>
                                </GridColumn>
                                <GridColumn width={1} textAlign={'center'}>
                                    <Icon name='angle down' circular/>
                                </GridColumn>
                            </Grid.Row>
                        </Grid>
                    </List.Content>
                </List.Item>
            </List>
        },
        content: { content: <TableRequest list={request} docId={label || docid} excelExport={excelExport}/> }
    }))

    return <Segment loading={isLoading}>
        <Accordion exclusive={false} fluid panels={panels} />
        <OrderExportExcelFile open={openExport} close={() => setOpenExport(false)} data={exportData} />
    </Segment>
}

const TableRequest = ({list = [], excelExport, docId }) => {
    return <Table celled striped compact unstackable selectable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell style={{ minWidth: '200px', width: '15%' }}>Производитель</Table.HeaderCell>
                <Table.HeaderCell style={{ minWidth: '180px', width: '15%' }}>Артикул</Table.HeaderCell>
                <Table.HeaderCell>Наименование</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '80px' }} textAlign="right">Количество</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '100px' }} textAlign="right">Цена</Table.HeaderCell>
                {/*<Table.HeaderCell textAlign="center" width={1}>Статус</Table.HeaderCell>*/}
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {!!list.length && list.map(({id, article, brand, description, quant, price, status = 0, stat = []}) => {
                const { label: { color = 'white' }, text } = STATUS_OPTIONS[status]
                return <Table.Row key={id} verticalAlign='top'>
                    <Table.Cell>{brand}</Table.Cell>
                    <Table.Cell>{article}</Table.Cell>
                    <Table.Cell>{description}</Table.Cell>
                    <Table.Cell textAlign="right">{quant}</Table.Cell>
                    <Table.Cell textAlign="right">{price || '-'}</Table.Cell>
{/*                    <Table.Cell textAlign="center">
                        <Label color={color} content={text} />
                    </Table.Cell>*/}
                </Table.Row>
            })}
        </Table.Body>
        <Table.Footer fullWidth>
            <Table.Row>
                <Table.HeaderCell colSpan='15'>
                    <Button color='pink' onClick={() => excelExport({ docId, list })}>
                        <Icon name='file excel' /> Export to Excel
                    </Button>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    </Table>
}


const FindResultTable = ({ list, cartItem }) => {
    const dispatch = useDispatch()
    const handlerQuant = (id, val) => dispatch(cartActions.add({ guid: id, quant: val }))
    const handlerAdd = (val) => dispatch(cartActions.add(val))

    return <Table celled striped selectable compact unstackable size="small">
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell style={{ width: '120px' }} textAlign="center">Дата</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '180px' }}>Производитель</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '150px' }} textAlign="right">Артикул</Table.HeaderCell>
                <Table.HeaderCell>Наименование</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '70px' }} textAlign="right">Кол-во</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '120px' }} textAlign="right">Наличие</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '70px' }} textAlign="right">Цена</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={1}>Статус</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '70px' }} textAlign="center">Заказ</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '90px' }} />
                <Table.HeaderCell style={{ width: '70px' }} />
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {list.map(({ guid, cdate, name, article, description, quant, stock, price2, status, label }) => {
                const { label: { color = 'white' }, text } = STATUS_OPTIONS[status]
                const { quant: q = 1 } = cartItem.find(el => el.guid === guid) || {}
                const isExistCart = cartItem.some(el => el.guid === guid)
                return <Table.Row key={guid}>
                    <Table.Cell textAlign="center">
                        {dayjs(cdate).format('DD.MM.YYYY HH:mm')}
                    </Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell textAlign="right">{article}</Table.Cell>
                    <Table.Cell>{description}</Table.Cell>
                    <Table.Cell textAlign="right">{quant}</Table.Cell>
                    <Table.Cell textAlign="right">{stock}</Table.Cell>
                    <Table.Cell textAlign="right">{price2}</Table.Cell>
                    <Table.Cell textAlign="center">
                        <Label color={color} content={text} />
                    </Table.Cell>
                    <Table.Cell>
                        <Label style={{width: '110px', textAlign: 'center'}} size="large">{label || '-'}</Label>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        <NumberInput
                            buttonPlacement="right"
                            allowEmptyValue
                            placeholder=""
                            minValue={1}
                            maxValue={100}
                            showError={Boolean(!q)}
                            value={String(q)}
                            onChange={(val) => handlerQuant(guid, val)}
                            className="inputRight"
                        />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        <Button
                            disabled={Boolean(!q)} fluid
                            onClick={() => isExistCart ? dispatch(cartActions.open(true)): handlerAdd({quant: +q, guid})} icon='cart'
                            positive={isExistCart}
                        />
                    </Table.Cell>
                </Table.Row>
            })}
        </Table.Body>
    </Table>
}