import { useState } from "react"
import {Invoice} from "../../../components"
import { Button, Modal, Segment, Header } from "semantic-ui-react"

export const CreateModal = ({ close }) => {
    const [open, setOpen] = useState(false)
    const closed = () => {
        setOpen(false)
        setTimeout(() => close(), 1000)
    }
    return <>
        <Button content='Создать запрос' icon='plus' labelPosition='left' color="blue" onClick={() => setOpen(true)} />
        <Modal
            size='large'
            closeOnDimmerClick={false}
            open={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Header style={{ padding: '0.6em 1.25em'}}>
                <Header as='h4'>Создать запрос</Header>
            </Modal.Header>
            <Modal.Content>
                <Segment>
                    <Invoice close={closed} />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                {/*<Button positive disabled>Да</Button>*/}
                <Button negative onClick={closed}>Закрыть</Button>
            </Modal.Actions>
        </Modal>
    </>
}