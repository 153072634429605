import React, {useRef, useState} from "react";
import { ExcelRenderer } from "react-excel-renderer";
import {Button, Divider, Form, Grid, Modal, Segment, Select, Table} from "semantic-ui-react";
import noticeError from "../../../hooks/useError";

export const ModalExcelExportClient = ({open, close, addBuffer, uuid }) => {
    const [file, setFile] = useState(null)
    const [reading, setReading] = useState([
        {name: 'A', key: 0, id: 0},
        {name: 'B', key: 1, id: 1},
        {name: 'C', key: 2, id: 2},
        {name: 'D', key: 3, id: 3},
    ])
    const [readRow, setReadRow] = useState(1)
    const inputRef = useRef()

    const onChangeReadRow = (e, option) => setReadRow(+option.value)
    const onChangeSelect = (e, option) => {
        const { value, id } = option
        const key = file?.cols.find(({ name }) => name === value)
        setReading(prev => !!prev?.[id] ? prev.map(
            (item, i) => i === id ? { ...key, id } : item
        ) : [...prev, ({...key, id})] )
    }

    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) console.log(err);
            else {
                setFile({
                    cols: resp.cols,
                    rows: resp.rows
                });
            }
        });
    }

    const tableColumn = ['Производитель', 'Артикул', 'Наименование', 'Количество']
    const shortData = file?.rows?.filter(item => item.length)
    const shortTable = shortData?.slice(readRow, 7) || []

    const LoadExcel = () => {
        if(!shortTable.every(item => !checkValidRow(item))) {
            noticeError({message: 'Загружаемые данные содержат ошибки, проверьте правильность данных и попробуйте еще раз'})
            return
        }
        shortData?.slice(readRow).map(data => addBuffer({
            brand: { text: String(data[reading[0]?.key]) },
            article: String(data[reading[1]?.key]),
            description: '',
            quantity: data[reading[3]?.key],
            uuid,
            id: Math.random() * 100
        }))
        close()
    }

    // Функция проверки строки на валидность
    const checkValidRow = (item) => {
        return (!item[reading[0]?.key] || String(item[reading[0]?.key]).length < 2 || String(item[reading[0]?.key]).length > 32)
        || (!item[reading[1]?.key] || (String(item[reading[1]?.key]).length <= 2 || String(item[reading[1]?.key]).length > 32))
        || (String(item[reading[2]?.key]).length && String(item[reading[2]?.key]).length > 255)
        || !Number.isFinite(parseInt(item[reading[3]?.key])) || parseInt(item[reading[3]?.key]) > 1000
        || !Number.isInteger(item[reading[3]?.key])
    }

    return <Modal size='large' closeOnDimmerClick={false} open={open} onClose={close}>
        <Modal.Header>Импорт запроса из Excel [REQUEST]</Modal.Header>
        <Modal.Content>
            <Segment padded>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form>
                                <Form.Field>
                                    <Button content="Выбрать файл" labelPosition="left" icon="file"
                                            onClick={() => inputRef.current.click()}/>
                                    <input ref={inputRef} type="file" hidden onChange={fileHandler}/>
                                    <Button floated='right' onClick={LoadExcel}>Загрузить</Button>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Table selectable celled compact size='small'>
                                <Table.Header>
                                    <Table.Row>
                                        {tableColumn.map((title, i) => <Table.HeaderCell key={i}>{title}</Table.HeaderCell>)}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {shortTable.map((item, i) => {
                                        const error = checkValidRow(item)
                                        return <Table.Row key={i} negative={error} positive={!error}>
                                            <Table.Cell>{item[reading[0]?.key]}</Table.Cell>
                                            <Table.Cell>{item[reading[1]?.key]}</Table.Cell>
                                            <Table.Cell>{item[reading[2]?.key]}</Table.Cell>
                                            <Table.Cell>{item[reading[3]?.key]}</Table.Cell>
                                        </Table.Row>
                                    })}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Grid columns={2} divided style={{margin: 0}}>
                                <Grid.Row style={{padding: '5px 0'}}>
                                    <Grid.Column width={5}>
                                        <Select
                                            style={{minWidth: "55px", fontSize: 11}}
                                            value={readRow}
                                            onChange={onChangeReadRow}
                                            options={[1, 2, 3, 4, 5, 6].map((index, i) => ({key: i, text: i + 1, value: i}))}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>Читать со строки</Grid.Column>
                                </Grid.Row>
                                {tableColumn.map((head, i) => {
                                    return <Grid.Row key={i} style={{padding: '5px 0'}}>
                                        <Grid.Column width={5}>
                                            <Select
                                                id={i}
                                                clearable
                                                value={reading[i].name}
                                                onChange={onChangeSelect}
                                                style={{minWidth: "55px", fontSize: 11}}
                                                options={file?.cols?.map(({name, key}) => ({key, text: name, value: name}))}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>{head}</Grid.Column>
                                    </Grid.Row>
                                })}
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Segment padded style={{ height: '20vh', overflowX: 'hidden', overflowY: 'auto' }}>
                                <Table basic='very' size='small' fixed>
                                    <Table.Header>
                                        <Table.Row>
                                            {file?.cols?.map(({name}, i) => <Table.HeaderCell key={i}>{name}</Table.HeaderCell>)}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {file?.rows?.filter(k => k.length).map(item => <Table.Row>{file?.cols?.map((_, i) => <Table.Cell>{item[i]}</Table.Cell>)}</Table.Row>)}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Segment>

        </Modal.Content>
        <Modal.Actions>
            <Button negative onClick={close}>
                Закрыть
            </Button>
        </Modal.Actions>
    </Modal>
}