import create from "zustand";

const INVOICE_BUFFER = 'invoiceBuffer'
const REQUEST_BUFFER = 'requestBuffer'

const useStore = create(set => ({
    [INVOICE_BUFFER]: [],
    addBuffer: (item) => set(state => ({[INVOICE_BUFFER]: [item, ...state[INVOICE_BUFFER]]})),
    removeBuffer:  id => set(state => ({[INVOICE_BUFFER]: state[INVOICE_BUFFER].filter(list => list.id !== id)})),
    removeBufferAll: () => set(state => ({[INVOICE_BUFFER]: []})),

    [REQUEST_BUFFER]: [],
    addRequestBuffer: (item) => set(state => ({[REQUEST_BUFFER]: [...state[REQUEST_BUFFER], ...item]})),
    removeRequestBuffer:  id => set(state => ({[REQUEST_BUFFER]: state[REQUEST_BUFFER].filter(list => list.id !== id)})),
    removeRequestBufferAll: () => set({[REQUEST_BUFFER]: []})
}));

export default useStore;