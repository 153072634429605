import * as axios from "axios";
import {store} from "../_store";
const BASE_URL = process.env.REACT_APP_API_URL
const authToken = () => store.getState().auth.user?.token
const Api = () => axios.create({ baseURL: BASE_URL, headers: { Authorization: `Bearer ${authToken()}`} });
const authUuid = (data) => ({
    ...data,
    uuid: store.getState().auth.user?.uuid
})

export { Api, authUuid }